@use "sass:color";
@import "~@/styles/variables";

$bg-color: $color-tint-brand-primary;

// Consider adding this to nui if it is commonly used
.link {
  background: $bg-color;
  border: 1px solid $color-foreground-on-tint;

  &:hover,
  &:focus {
    background: color.adjust($bg-color, $lightness: -5%);
  }

  &:active,
  &.nui-active {
    background: color.adjust($bg-color, $lightness: -10%);
  }

  &[disabled]:hover {
    background: $bg-color;
  }
}
