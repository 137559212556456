@import "~@/styles/variables";

.promoBtContainer p {
  margin-top: 16px;
  font-weight: 600;
  color: #FFF;
}

.quoteCard {
  @include spacing-inset-24;

  p {
    margin-bottom: 0;
  }
}

.headerWrapper {
  // background-color: var(--awb-color1); ?? what color is this and does it matter?
  background-repeat: no-repeat;
  border-width: 0;
  border-color: rgb(234 233 233);
  border-style: solid;
  background-image: url("/home/home-hero.webp");
  background-position: 85% bottom;
  background-size: 600px;

  .headerSection {
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
  }

  .headerTitle {
    max-width: 450px;
    width: 100%;

    @include bp-mobile {
      max-width: 100%;
    }
  }

  .promoBtContainer p {
    margin-top: 8px;
    font-weight: 600;
    color: #B85548;
    margin-left: 65px;
  }
}

.heroHolder {
  margin-bottom: 64px;
  margin-top: 64px;
}

.headerBuyTry {
  margin-top: $spacing-stack-32;
}

.quoteImageColumn {
  justify-content: flex-end;

  @media screen and (max-width: 900px) {
    align-items: center;
    bottom: 100px;
    position: relative;
    margin-bottom: -100px;

    img {
      max-width: 200px;
    }
  }
}

.featuresWrapper {
  background-color: $color-background-primary;
  background-position: center top;
  background-repeat: repeat-x;
  border-width: 0;
  border-color: rgb(234 233 233);
  border-style: solid;
  background-image: url("/home/tiling-triangles-top.png");
}

.featureListItem {
  background-size: 48px;
  margin-bottom: $spacing-inline-32;
  padding-left: 50px;
}

.securityWrapper {
  background-color: $color-foreground-primary;
  background-position: right center;
  background-repeat: no-repeat;
  border-width: 0;
  border-color: rgb(234 233 233);
  border-style: solid;
  background-image: url("/home/security-bg.webp");
  background-size: auto;
}

@media only screen and (min-width: 320px) {
  .headerWrapper {
    background-position: 70% bottom;
    background-size: 95%;
  }

  .heroHolder {
    margin-top: 50px;
    margin-bottom: 80%;
    width: 100%;
  }
}

@include bp-non-mobile {
  .headerWrapper {
    background-position: 475px bottom;
    background-size: 400px;
  }

  .heroHolder {
    margin-top: 20px;
    margin-bottom: 60px;
    width: 60%;
  }

  .heroHolder h2 {
    font-size: $font-size-40;
  }

  .quoteColumn {
    &:nth-child(3) {
      max-width: 200px;
    }
  }

  .securitySection {
    align-items: flex-start;
  }
}

@media only screen and (min-width: 975px) and (max-width: 990px) {
  .heroHolder {
    width: 48%;
  }
}

@media only screen and (min-width: 991px) {
  .headerWrapper {
    background-position: 120% bottom;
    background-size: 600px;
  }

  .heroHolder {
    margin-top: 120px;
    margin-bottom: 120px;
    width: 50%;
  }

  .featuresTitle {
    width: 40%;
  }

  .featuresLeftCol {
    flex-basis: 60%;
  }

  .featuresRightCol {
    flex-basis: 40%;
  }
}

@media only screen and (min-width: 1200px) {
  .headerWrapper {
    background-position: 90% bottom;
    background-size: 600px;
  }
}

@media only screen and (min-width: 1600px) {
  .headerWrapper {
    background-position: 75% bottom;
    background-size: 650px;
  }
}

@media only screen and (max-width: 900px) {
  .quoteImageColumn {
    order: 100;
    align-items: center;
    flex-basis: 100%;
    bottom: 100px;
    position: relative;
    margin-bottom: -100px;
  }
}

@include bp-mobile {
  .heroHolder {
    text-align: center;
  }

  .headerWrapper .promoBtContainer p {
    margin-left: 0;
  }

  .featuresWrapper {
    h2,
    h3 {
      text-align: center;
    }
  }

  .featuresIconList {
    max-width: none;
  }

  .featureListItem {
    padding-left: 70px;
    min-height: 45px;
    display: flex;
    align-items: center;
  }

  .quoteImageColumn {
    margin-bottom: 0;
    bottom: 0;
    flex-basis: auto;
  }

  .securityWrapper {
    background-position: center -90px;

    .securitySection {
      padding-top: $spacing-stack-24;
      padding-bottom: $spacing-stack-24;
    }
  }

  .securityTextWrapper {
    margin-top: 200px;
  }
}
