@import "~@theneatcompany/nui/lib/settings";

$breakpoint-mobile-value: 767;
$breakpoint-mobile: #{$breakpoint-mobile-value}#{"px"};
$breakpoint-non-mobile-value: $breakpoint-mobile-value + 1;
$breakpoint-non-mobile: #{$breakpoint-non-mobile-value}#{"px"};
$breakpoint-mobile-small-value: 639;
$breakpoint-mobile-small: #{$breakpoint-mobile-small-value}#{"px"};
$breakpoint-non-mobile-small-value: $breakpoint-mobile-small-value + 1;
$breakpoint-non-mobile-small: #{$breakpoint-non-mobile-small-value}#{"px"};
$breakpoint-mobile-tablet-value: 1023;
$breakpoint-tablet: #{$breakpoint-mobile-tablet-value}#{"px"};
$font-sizes: 8, 10, 12, 14, 16, 18, 24, 32, 40, 48, 64;
$spacing-sizes: 0, 2, 4, 8, 12, 16, 18, 24, 32, 48, 64;
$gap-percentages: 5 10;
$col-sizes: 20 25 33 40 45 50 55 60 66 70 75 80;
$max-col-sizes: 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900;
$top-banner-height: 60px;

@mixin bp-mobile($size: "medium") {
  $bp: $breakpoint-mobile;

  @if $size == "small" {
    $bp: $breakpoint-mobile-small;
  }

  @media only screen and (max-width: $bp) {
    @content;
  }
}

@mixin bp-tablet {
  $bp: $breakpoint-tablet;

  @media only screen and (max-width: $bp) {
    @content;
  }
}

@mixin bp-non-mobile($size: "medium") {
  $bp: $breakpoint-non-mobile;

  @if $size == "small" {
    $bp: $breakpoint-non-mobile-small;
  }

  @media only screen and (min-width: $bp) {
    @content;
  }
}

@mixin gap-sizes {
  @each $size in $spacing-sizes {
    .gap-#{$size} { gap: #{$size}px; }
  }

  @each $size in $gap-percentages {
    .pGap-#{$size} { gap: #{$size}#{"%"}; }
  }
}

@mixin max-w-sizes {
  @each $size in $max-col-sizes {
    .max-w-#{$size} {
      width: 100%;

      @media screen and (min-width: #{$size}px) {
        max-width: #{$size}px;
      }
    }
  }
}

@mixin spacing-classes {
  @each $size in $spacing-sizes {
    .pt-#{$size} {
      padding-top: #{$size}px;
    }

    .pb-#{$size} {
      padding-bottom: #{$size}px;
    }

    .pl-#{$size} {
      padding-left: #{$size}px;
    }

    .pr-#{$size} {
      padding-right: #{$size}px;
    }

    .px-#{$size} {
      padding-left: #{$size}px;
      padding-right: #{$size}px;
    }

    .py-#{$size} {
      padding-bottom: #{$size}px;
      padding-top: #{$size}px;
    }

    .mt-#{$size} {
      margin-top: #{$size}px;
    }

    .mb-#{$size} {
      margin-bottom: #{$size}px;
    }

    .ml-#{$size} {
      margin-left: #{$size}px;
    }

    .mr-#{$size} {
      margin-right: #{$size}px;
    }

    .mx-#{$size} {
      margin-left: #{$size}px;
      margin-right: #{$size}px;
    }

    .my-#{$size} {
      margin-bottom: #{$size}px;
      margin-top: #{$size}px;
    }
  }
}

@mixin stacked-buttons {
  align-items: stretch;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 300px;
}

$color-foreground-quaternary: #929EAA;
$pink: #FD38E8;

@mixin no-auto-zoom-input {
  input,
  input:focus,
  textarea,
  textarea:focus {
    font-size: $font-size-16;

    &::placeholder {
      font-size: $font-size-16;
    }
  }
}
