.options {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);

  div {
    text-align: center;
  }

  img {
    margin: 0 auto 20px;
  }

  h3 {
    margin-bottom: 15px;
    font-size: 20px;
  }

  p {
    font-size: 15px;
  }
}

.uploadOptions {
  h2,
  h3,
  p {
    color: #FFF;
  }

  .header {
    margin-bottom: 20px;
    text-align: center;

    p {
      max-width: 550px;
    }
  }
}

@media screen and (max-width: 768px) {
  .options {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .options {
    grid-template-columns: repeat(1, 1fr);
  }
}
