.sliderContainer {
  font-style: italic;
  margin-top: 10px;

  .testimonialText p {
    color: #FFF;
    font-size: 16px;
  }

  .author {
    margin-bottom: 10px;

    h4 {
      color: #FFF;
      font-size: 16px;
    }
  }
}

.buttonContainer {
  display: flex;
  gap: 10px;

  .leftArrow,
  .rightArrow {
    color: #FFF;
    background: transparent;
    border: 1px solid #FFF;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }
}

.multiSlides {
  .testimonialWrapper {
    display: flex;
    gap: 35px;
  }

  .testimonialText p,
  .author h4 {
    color: #000;
    font-style: normal;
  }

  .testimonialText {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p span {
      display: none;
    }
  }

  .author {
    h4 {
      font-weight: 600;
    }
  }

  .testimonialItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 15px 15px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / .1);
    position: relative;

    &:before {
      content: url("/icons/quote.png");
      position: absolute;
      top: -20px;
      left: -20px;
      width: 41px;
      height: 41px;
    }
  }

  .leftArrow,
  .rightArrow {
    color: #515EDA;
    border: 1px solid #515EDA;
    margin-top: 20px;
  }

  .buttonContainer {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .buttonContainer {
    justify-content: center;
  }
}
