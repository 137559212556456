@import "~@/styles/variables";

.sectionWrapper {
  overflow: hidden;
}

.section {
  padding-bottom: 0;
  padding-top: 48px;
}

.columns {
  @include bp-mobile {
    width: unset;
  }
}

.statsColumn {
  @include bp-non-mobile {
    min-width: 627px;
  }
}

.statCallout {
  @include spacing-inset-16;

  margin-bottom: 48px;
  background-color: #2230B2;
}

.statGroup {
  padding-left: 90px;
  background-repeat: no-repeat;
  background-position: left top;

  &.search,
  &.lifepreserver {
    @include bp-mobile {
      background-position: 27px top;
    }

    @include bp-non-mobile {
      padding-left: $spacing-inline-64;
    }
  }

  &.stopwatch {
    background-image: url("/home/icon-stats-stopwatch.svg");
  }

  &.search {
    background-image: url("/home/icon-stats-search.svg");
  }

  &.lifepreserver {
    background-image: url("/home/icon-stats-lifepreserver.svg");
  }
}

.stat {
  font-size: 64px;
}

.statDetails {
  @include bp-non-mobile {
    max-width: 228px;
  }

  line-height: 1.2;
}

.imageColumn {
  justify-content: flex-end;
  align-items: flex-end;
}
